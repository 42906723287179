import AppImage from '@theme/components/atom/AppImage'
import AppLink from '@theme/components/atom/AppLink'
import FooterMainLastColumnHook from '@theme/components/shop/FooterMainLastColumnHook'
import SocialNetworkLinks from '@theme/components/content/SocialNetworkLinks'
import _ from 'lodash'

export default {
  components: {
    AppImage,
    AppLink,
    FooterMainLastColumnHook,
    SocialNetworkLinks,
  },
  props: {
    menus: {
      type: Array,
      default() {
        return [
          this.$store.state.menu.footer_main_1,
          this.$store.state.menu.footer_main_2,
          this.$store.state.menu.footer_main_3,
          this.$store.state.menu.footer_main_4,
        ]
      },
    },
  },
  data() {
    return {
      columnLastClass: null,
      columnMenuClass: null,
      columnMenuClassDefault: null,
      image: null,
      notEmptyMenus: null,
      opened: null,
    }
  },
  methods: {
    setColumnMenuClassDefault() {
      this.columnMenuClassDefault = 'col-md mb-3'
      if (this.notEmptyMenus.length > 3) {
        this.columnMenuClassDefault = this.columnMenuClassDefault + ' col-xl-6'
      }
    },
    toggle(groupId) {
      if (this.opened === groupId) {
        this.opened = null
        return
      }
      this.opened = groupId
    },
  },
  mounted() {
    this.image =
      _.get(this.$store.state, 'globals.footer.footer_image_1') &&
      this.$store.state.globals.footer.footer_image_1.length > 0 &&
      this.$store.state.globals.footer.footer_image_1[0].path

    this.notEmptyMenus = this.menus.filter(menu => menu?.menuItems?.length > 0)

    this.setColumnMenuClassDefault()

    if (!this.image) {
      this.columnMenuClass = this.columnLastClass = this.columnMenuClassDefault
    } else {
      this.columnMenuClass = this.columnMenuClassDefault
      this.columnLastClass = 'col-xl-9 d-flex flex-column d-md-block mt-md-5 mt-lg-0 ml-xl-auto'
    }
  },
}
